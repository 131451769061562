import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import Button from "../../../components/Buttons/Button";
import Icon from "../../../components/Icon/Icon";
import SideBarLinks from "../../../constants/SideBarLinksAdmin.json";
import { useTranslation } from "react-i18next";
import { fill } from "lodash";

const LeftSidebar = ({open, setOpen}) => {
    const location = useLocation();
    const [show, setshow] = useState(false);
    const [linkRefs, setlinRefs] = useState(SideBarLinks);
    const {t} = useTranslation()
    const handleClick = (e) => {
        e.preventDefault();
        setOpen(!open);
    };

    useEffect(() => {
        const link = location?.pathname;
        const index = SideBarLinks.findIndex(item => item.link === link);
        if (location?.pathname === `/admin/login`) {
            active(0);
        }
        if (index >= 0) {
            active(index);
        }
    }, [])

    useEffect(() => {
        if (location?.pathname === `/admin/mosque/add`) {
            let newLinkRefs = SideBarLinks.map((item) => {
                item.active = item?.link === "/admin/mosque/add";
                return item;
            });
            setlinRefs(newLinkRefs);
        }
    }, [location]);

    const active = (i) => {
        let newLinkRefs = SideBarLinks.map((link) => {
            link.active = false;
            return link;
        });
        SideBarLinks[i].active = true;
        setlinRefs(newLinkRefs);
    };

    const handleLogOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("user_type");
        window.location = "/admin/login";
    }

    return (
        <div
            className={open ? "whiteBg" : "blackBg"}
        >
            <div
                className={open ? "wLogo" : "bLogo"}
                style={{paddingLeft:"0px"}}
            >
                <img style={{ height: open ?"85px":"40px", width: open ? "271.5px" : "auto", paddingLeft: open ? "0px" : "8px",marginLeft:open?'':'20px',objectFit:open?'':'cover' }} src="/assets/mosqapp-logo-new.svg"/>
            </div>
            <div
                className={open ? "wScroll" : "bScroll"}
            >
                {open ? (
                    <>
                        {SideBarLinks &&
                            SideBarLinks.map((item, i) => (
                                <div className="li-content" style={{padding: "15px 35px"}} key={item?.name}>
                                    <NavLink
                                        to={item.link}
                                        onClick={() => active(i)}
                                        className={item.active ? "activeClass" : null}
                                    >
                                        <Icon
                                            iconName={item.icon}
                                            width="30px"
                                            height="30px"
                                            color={item.active ? "#fff" : "#D0004B"}
                                        ></Icon>
                                        <h5>{t(item.name)}</h5>
                                    </NavLink>
                                </div>
                            ))}
                    </>
                ) : (
                    <>
                        {SideBarLinks &&
                            SideBarLinks.map((item, i) => (
                                <div className="li-content justify-content-center" style={{padding: "15px 0px"}}>
                                    <NavLink
                                        to={item.link}
                                        onClick={() => active(i)}
                                        className={item.active ? "activeClassWhite" : null}
                                    >
                                        <Icon
                                            iconName={item.icon}
                                            width="30px"
                                            height="30px"
                                            color={item.active ? "#D0004B" : "#D0004B"}
                                        ></Icon>
                                    </NavLink>
                                </div>
                            ))}
                    </>
                )}
                {open ? (
                    <div className="li-content" style={{padding: "20px 35px"}}
                         onClick={() => setshow(true)}
                    >
                        <Link>
                            <Icon
                                iconName="wlogout"
                                color={open ? " #D0004B" : "#D0004B"}
                                width="22px"
                                height="22px"
                            ></Icon>
                            <h4 style={{color: " #D0004B"}}>{t('Logout')}</h4>
                        </Link>
                    </div>
                ) : (
                    <div onClick={() => setshow(true)}>
                        <Icon
                            cursor="pointer"
                            iconName="wlogout"
                            width="22px"
                            color="#D0004B"
                            m="auto"
                            mr="45%"
                            mt="18%"
                            height="22px"
                        ></Icon>
                    </div>
                )}
            </div>
            <div
                className={open ? "wToggle" : "bToggle"}
            >
                <Button
                    icon
                    onClick={handleClick}
                    type="blank"
                    iconName={open ? "whitetoggle" : "blacktoggle"}
                />
            </div>

            <Modal
                show={show}
                onHide={() => setshow(false)}
                className="logout-modal"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <button type="button" class="close" aria-label="Close"
                                onClick={() => setshow(false)}>
                    <span aria-hidden="true">
                      <Icon iconName="modalClose"/>
                    </span>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center">
                        <Icon iconName="Logout" style={{margin: "auto"}}/>
                        <div className="text">{t('Are you sure you want to log out?')}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setshow(false)} className="cancel">
                    {t('Cancel')}
                    </button>
                    <button onClick={() => handleLogOut()} className="logout">
                    {t('Logout')}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default LeftSidebar;
