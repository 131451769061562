import Box from "../Box";
import Icon from "../Icon";
import PropTypes from "prop-types";
import React from "react";
import ReactTooltip from 'react-tooltip'
import styled from "styled-components";
import {v4 as uuidv4} from "uuid";
import Loader from "../Loader2";
import Checkbox from "../Checkbox";
import { useTranslation } from "react-i18next";

const StyledTableHeader = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-left: 10px;
`;
const MobileDesign = styled(Box)`
  // margin-right:30px;
  line-height: 22px;
  @media only screen and (max-width: 767px) {
    margin-right: 0px;
    padding: 0px 7px;
    font-size: 13px;
  }
`;
const MobileScroll = styled(Box)`
  overflow: auto;

  overflow-x: hidden;
  max-height: 380px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    // background: #9197B3;
    // border-radius: 5px;
    background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/Line.svg?updatedAt=1689592501360");
    background-repeat: no-repeat;
  }
`
const StyledBox = styled(Box)`
`;

const NoResultFoundText = styled.div`
  align-items: center;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 14px;
  width: 250px;
  color: rgba(152, 152, 152, 0.8);
  @media (max-width: 767px) {
    font-size: 14px;
    width: 210px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px !important;
  }
`;

const Table = ({
                   columns,
                   data,
                   dataCount,
                   noResultIcon = "noResult",
                   tooltipProps = {},
                   noResultText,
                   noResultProps = {
                       height: "20%",
                       display: "flex",
                       flexDirection: "column",
                       alignItems: "center",
                       justifyContent: "center",
                       marginTop: "6%",
                   },
                   gridTemplateColumnsHeader,
                   gridTemplateColumnsBody,
                   zeroState,
                   fetching,
                   onClickAll,
                   isAll,
                   noTableClass = false
               }) => {

    let tableProps = dataCount < 5 ? {height: "20%"} : {};
    const {t}  = useTranslation()
    const renderTableBody = () => {
        if (fetching) {
            return (
                <Box
                    my="40px"
                    {...{...noResultProps, ...tableProps}}
                >
                    <Loader color="primary"/>
                </Box>
            );
        }
        if (zeroState) {
            return zeroState;
        }
        if (!data?.length) {
            return (
                <Box
                    {...{...noResultProps, ...tableProps}}
                >
                    <Icon iconName={noResultIcon}/>
                    <NoResultFoundText>{noResultText}</NoResultFoundText>
                </Box>
            );
        }
        return (
            <MobileScroll className={noTableClass?"":"member-table-body"}>
                <Box  display="block" {...tableProps?.tbodyProps}>
                    {data.map((row,i) => (
                        <MobileDesign key={i}>
                            <Box
                                display="grid"
                                gridTemplateColumns={gridTemplateColumnsBody}
                                //  borderBottom= "1px solid #F3F3F3"

                                {...tableProps?.trProps}
                                className="table-row-hover mosque-info-line"
                            >
                                {row.map((cell, colIndex) => (
                                    <StyledBox
                                        padding="15px 21px"
                                        height="100%"
                                        // fontSize="sm"
                                        fontSize="16px"
                                        fontStyle="normal"
                                        fontWeight="400"
                                        display="flex"
                                        alignItems="center"
                                        // lineHeight="30px"
                                        {...tableProps?.tdProps}
                                        className="td-hover"
                                        style={
                                            {
                                                ...columns[colIndex]?.styleColumn,
                                                ...columns[colIndex]?.styleCells,
                                            } ?? {}
                                        }
                                        key={colIndex}
                                    >
                                        {cell}
                                    </StyledBox>
                                ))}
                            </Box>
                        </MobileDesign>
                    ))}
                </Box>
            </MobileScroll>
        );
    };
    const renderTableHeader = () => {
        return (
            <MobileDesign>
                <Box
                    position="sticky"
                    top={0}
                    zIndex={10}
                    // background= "#F5F6FA"
                    {...tableProps?.theadProps}
                >
                    <Box
                        display="grid"
                        //  background= "#F5F6FA"
                        gridTemplateColumns={gridTemplateColumnsHeader}
                        fontSize="14px"
                        fontWeight="400"
                        color="#1a1a1a"
                        textAlign="center"
                        {...tableProps?.thRowProps}
                    >
                        {renderTableHeaderRows()}
                    </Box>
                </Box>
            </MobileDesign>
        );
    }

    const renderTableHeaderRows = () => {
        const tableHeader = columns?.map(
            ({id, heading, arrowUpDown, subHeading, tooltip, styleColumn, styleHeader}) => (
                <Box
                    key={id ?? uuidv4()}
                    // variant="text4"
                    height="61px"
                    padding="16px"
                    borderColor="lightGrey.10"
                    borderBottom="1px solid #F3F3F3"
                    // bg="#61B6E2"
                    display='flex'
                    alignItems='center'
                    justifyContent='flex-start'
                    {...tableProps?.thProps}
                    style={{...styleColumn, ...styleHeader} ?? {}}
                >
                    <StyledTableHeader>
                        <div style={{textAlign: 'left', minWidth: 'max-content'}}>
                            <Box display="flex" alignItems="center">
                                {heading === "All" &&
                                    <Checkbox
                                        checked={isAll}
                                        onChange={onClickAll}
                                        mr="12px"
                                    />
                                }
                                {heading}
                                {arrowUpDown && (
                                    <StyledIcon
                                        iconName="arrowUpDown"
                                        color="darkgray"
                                        data-tip={tooltip}
                                        {...tooltipProps}
                                    />
                                )}
                            </Box>
                            {subHeading}
                        </div>
                        {tooltip && (
                            <StyledIcon
                                iconName="info"
                                color="darkgray"
                                data-tip={tooltip}
                                {...tooltipProps}
                            />
                        )}
                    </StyledTableHeader>
                </Box>
            ),
        );
        return tableHeader;
    };

    return (
        <>
            <ReactTooltip
                className="tooltip special-tooltip"
                multiline
                place="top"
                effect="solid"
            />
            <Box width="100%"  mb="1rem" {...tableProps?.tableProps}>
                {renderTableHeader()}
                {renderTableBody()}
            </Box>
        </>
    );
};

Table.propTypes = {
    columns: PropTypes.arrayOf([
        PropTypes.shape({
            header: PropTypes.element,
            tooltip: PropTypes.string,
        }),
    ]).isRequired,
    // eslint-disable-next-line react/require-default-props
    data: PropTypes.arrayOf([
        PropTypes.arrayOf([PropTypes.string, PropTypes.number, PropTypes.element]),
    ]),
    // eslint-disable-next-line react/require-default-props
    noResultIcon: PropTypes.string,
};

export default Table;
