import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Label from "../../../../../components/Label";
import BarChart from "../../../../../components/D3Charts/BarChart";
import { useTranslation } from "react-i18next";
import { fetchEntityFinRevenue } from "../../../adminAction";
import axios from "axios";
import { apiURL, baseUrl } from "../../../../../app.config";

const PlanMembers = () => {
  const dashboardStats = useSelector(
    (state) => state.adminReducer?.dashboardStats
  );
  const [graphData, setGraphData] = useState({});

  const getData = () => {
    const data = [];
    for (const property in graphData) {
      data.push(graphData[property]);
    }
    return data;
  };
  const { t } = useTranslation();
  const getCategories = () => {
    const data = [];
    for (const property in graphData) {
      data.push(property);
    }
    return data;
  };

  useEffect(() => {
    const getGraphData = async () => {
      try {
        const graphData = await axios.get(
          `${apiURL}admin/dashboard/payments-per-mosque`
        );
        setGraphData(graphData?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGraphData();
  }, []);

  return (
    <div
      className="overview-card"
      style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
    >
      <Label variant="text1">{t("Overview")}</Label>
      <Label variant="text5" color="#7E7E7E">
        {t("Amount per Mosque")}
      </Label>
      <div className="white_box" style={{ height: "100%" }}>
        <div className="" style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <BarChart
              xdim={780}
              ydim={400}
              margin={{ top: 0, bottom: 60, left: 0, right: 0 }}
              name="Amount"
              data={getData()}
              categories={getCategories()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanMembers;
