import React, {useEffect, useState, useRef} from "react";

import HeaderData from "../../constants/HeaderData.json";
import {Link} from "react-router-dom";
import "../../css/header.css";
import Icon from "../Icon/Icon";
import styled from "styled-components";
import Box from "../Box";
import upperCase from 'lodash/upperCase';
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../modules/Customer/customerAction";
import { useTranslation } from 'react-i18next';
import i18next from '../../services/i18next'
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { useNavigate } from "react-router-dom";

const Container = styled(Box)`
  position: fixed;
  width: 100%;
  z-index: 9;
`;

export default function Header() {
    const navigate = useNavigate();
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const [linkRefs, setlinRefs] = useState(HeaderData);
    const { t } = useTranslation();
    console.log('t',t);
    const[isLanguageDrop, setIsLanguageDrop] = useState(false)
    // const [currentLanguage, setCurrentLanguage] = useState('')

    const language_code = useSelector((state) => state?.customerReducer?.language_code);

    const handleClickLanguageDrop = () => {
        setIsLanguageDrop(true);
    }
    const handleClickLanguageLink = (code) => {
        setIsLanguageDrop(false);
        dispatch(setLanguage(code));
    }

    const scrollListener = () => {
        const contact = isScrolledIntoView(document.getElementById('contact-state'));
        const about = isScrolledIntoView(document.getElementById('about-state'));
        const mosque = isScrolledIntoView(document.getElementById('mosque-state'));

        if (contact && !linkRefs[2].active) {
            let newLinkRefs = linkRefs.map((link) => {
                link.active = false;
                return link;
            });
            newLinkRefs[2].active = true;
            setlinRefs(newLinkRefs)
        } else if (about && !contact && !linkRefs[1].active) {
            let newLinkRefs = linkRefs.map((link) => {
                link.active = false;
                return link;
            });
            newLinkRefs[1].active = true;
            setlinRefs(newLinkRefs)
        } else if (mosque && !linkRefs[0].active) {
            let newLinkRefs = linkRefs.map((link) => {
                link.active = false;
                return link;
            });
            newLinkRefs[0].active = true;
            setlinRefs(newLinkRefs)
        }
    }


    const isScrolledIntoView = (el) => {
        if (el && el.getBoundingClientRect) {
            var rect = el.getBoundingClientRect();
            var elemTop = rect.top;
            var elemBottom = rect.bottom;

            var isVisible = elemTop + 290 < window.innerHeight && elemBottom >= 0;
            return isVisible;
        } else {
            return false;
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollListener);

        return () => window.removeEventListener('scroll', scrollListener);
    }, [])

    const active = (i) => {
        let newLinkRefs = linkRefs.map((link) => {
            link.active = false;
            return link;
        });
        linkRefs[i].active = true;
        setlinRefs(newLinkRefs);

        if (i === 0) {
            const categoryElement = document.getElementById(`mosque-state`);
            if (categoryElement && categoryElement.scrollIntoView) {
                categoryElement.scrollIntoView({behavior: "smooth"});
            }
        }
        if (i === 1) {
            const categoryElement = document.getElementById(`about-state`);
            if (categoryElement && categoryElement.scrollIntoView) {
                categoryElement.scrollIntoView({behavior: "smooth"});
            }
        }
        if (i === 2) {
            const categoryElement = document.getElementById(`contact-state`);
            if (categoryElement && categoryElement.scrollIntoView) {
                categoryElement.scrollIntoView({behavior: "smooth"});
            }
        }
    };

    const handleRegisterButton = () => {
        const categoryElement = document.getElementById(`onboarding-state`);
        if (categoryElement && categoryElement.scrollIntoView) {
          categoryElement.scrollIntoView({behavior: "smooth"});
        }
    };

    const handleLogoClick = () => {
        window.location.href = "/";
    };
   const handleLanguageSelect = () =>{
    const value = document.getElementById("language-select").value;
    i18next.changeLanguage(value)
    localStorage.setItem('language',value);
    console.log('language',value);
   }
    return (
        <Container className="elixir-header-fixed">
            <div className="for-mobile-menu">
                <button
                    type="button"
                    className="btn btn-primary menu-btn"
                    data-toggle="modal"
                    data-target="#exampleModal"
                >
                    {/* <img
          src="https://ik.imagekit.io/zwhvovcr84s/svg-edited_0Ya1vLGoi.svg?updatedAt=1683192794028"
          alt=""
          className="w-100"
        /> */}
                    <Icon iconName="Hamburger" iconSize="normal"/>
                </button>
                <div
                    className="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                <span aria-hidden="true">
                  <img
                      src="https://ik.imagekit.io/zwhvovcr84s/svg-edited__1__eMXfDj2vV.svg?updatedAt=1683193496294"
                      alt=""
                      className="w-100"
                  />
                </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ul>
                                    {linkRefs &&
                                        linkRefs.map((item, i) => (
                                            <li
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => active(i)}
                                                className={item.active ? "activeClassHeader" : null}
                                                key={i}
                                            >
                                                <Link href={item.link}>{item.name}</Link>
                                            </li>
                                        ))}
                                        {/* <li className="login_btn"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                    <Link to={"/login"} >{t('Login')}</Link>
                                </li> */}
                                <a className="login_btn btn" href="/login" alt="login">
                                 {t('Login')}
                                </a>
                               
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="logo-div">
                <div onClick={() => handleLogoClick()}>
                    <img src="assets/mosqapp-logo-new.svg"/>
                </div>
            </div>
            <div className="elixir-header-content">
                <ul>
                    {linkRefs &&
                        linkRefs.map((item, i) => (
                            <li
                                onClick={() => active(i)}
                                className={`menu-items ${item.active ? "activeClassHeader" : ""}`}
                            >
                                <Link className="fontIssue" to={item.link}>{t(item.name)}</Link>
                            </li>
                        ))}
                    <button href="" className="register_buttton" onClick={() => handleRegisterButton()}>
                        {t('Register')}
                    </button>
                    <a className="login_btn btn" href="/login" alt="login">
                    {t('Login')}
                    </a>
                    {/*<li className="lan-dp">
                        <Box display="flex" alignItems="center" cursor="pointer" onClick={() => handleClickLanguageDrop()}>
                            <Icon iconName="mapIcon" />
                            <Box ml="4px" mr="6px">{upperCase(language_code)}</Box>
                            <Icon iconName="arrowBottom" color="#D0004B"/>
                        </Box>
                        </li>*/}
                </ul>
                <LanguageSelector />
                {/* <div  className="language-select-container">
                    <select id="language-select" className="language-select" style={{
                        border:'none',
                        marginLeft:'20px'

                    }} onChange={handleLanguageSelect}>
                        <option value="en" selected={currentLanguage==='en'} >🇺🇸 English</option>
                        <option value="nl" selected={currentLanguage==='nl'}>🇳🇱 Dutch</option>
                    </select>
                </div> */}
            </div>
            {/*{isLanguageDrop &&
                <ul className="menuitemmap" style={{ top: "65px", right: "115px", padding: 0 }}>
                    <li onClick={() => handleClickLanguageLink(`nl`)}>Netherlands</li>
                    <li onClick={() => handleClickLanguageLink(`en`)}>English</li>
                    <li onClick={() => handleClickLanguageLink(`de`)}>Deutsch</li>
                    <li onClick={() => handleClickLanguageLink(`es`)}>Espanol</li>
                    <li onClick={() => handleClickLanguageLink(`fr`)}>Francais</li>
                </ul>
            }*/}
        </Container>
    );
}
