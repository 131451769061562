import React from "react";
import Icon from "../../../../../components/Icon";
import {useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
const PlanCard = () => {
    const {t} = useTranslation()
    const dashboardStats = useSelector(state => state.adminReducer?.dashboardStats)

    const renderGrowth = (cur, prev) => {
        const diff = cur - prev;
        let percentage = 0;
        if (prev !== 0) {
            percentage = (diff / prev) * 100;
        }
        return Math.abs(percentage).toFixed(2);
    }

    const renderIconName = (cur, prev) => {
        const diff = cur - prev;
        if (diff >= 0) {
            return 'upArrow';
        }
        return 'downArrow'
    }


    return (
     
            <div className="row">
                <div className="col-lg-4">
                    <div className="chart-box h-100">
                        <div className="d-flex">
                            <Icon
                                iconName="TotalDonation"
                                width="85px"
                                height="84px"
                                mr="15px"
                                mt="10px"
                            />
                            <div>
                                <small>{t("Total Income")}</small>
                                <h1>{`€${dashboardStats?.current_month_total_revenue.toFixed(2)}`}</h1>
                                <p className="d-flex align-items-center">
                                    <Icon iconSize="tiny" color="#00AC4F"
                                          iconName={renderIconName(dashboardStats?.current_month_total_revenue, dashboardStats?.last_month_total_revenue)}/>
                                    <label className={"green"}>
                                        {renderGrowth(dashboardStats?.current_month_total_revenue, dashboardStats?.last_month_total_revenue)}%
                                    </label>{t('This Month')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="chart-box h-100">
                        <div className="d-flex">
                            <Icon
                                iconName="PendingDonations"
                                width="85px"
                                height="84px"
                                mr="15px"
                                mt="10px"
                            />
                            <div>
                                <small>{t("Income in Progress")}</small>
                                <h1>{`€${dashboardStats?.current_month_pending_revenue.toFixed(2)}`}</h1>
                                <p className="d-flex align-items-center">
                                    <Icon iconSize="tiny" color="#FECB17"
                                          iconName={renderIconName(dashboardStats?.current_month_pending_revenue, dashboardStats?.last_month_pending_revenue)}/>
                                    <label className={"yellow"}>
                                        {renderGrowth(dashboardStats?.current_month_pending_revenue, dashboardStats?.last_month_pending_revenue)}%
                                    </label>{t('This Month')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="chart-box h-100">
                        <div className="d-flex">
                            <Icon
                                iconName="Userpaid"
                                width="85px"
                                height="84px"
                                mr="15px"
                                mt="10px"
                            />
                            <div className="d-flex flex-column justify-content-center">
                                <small>
                                    {t("Affiliated Mosques")}</small>
                                <h1>{`${dashboardStats?.current_month_onboarded_count}`}</h1>
                                {/* <p className="d-flex align-items-center">
                                    <Icon iconSize="tiny" color="#4763E4"
                                          iconName={renderIconName(dashboardStats?.current_month_onboarded_count, dashboardStats?.last_month_onboarded_count)}/>
                                    <label className={"blue"}>
                                        {renderGrowth(dashboardStats?.current_month_onboarded_count, dashboardStats?.last_month_onboarded_count)}%
                                    </label>{t('This Month')}
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    );
}

export default PlanCard;
