import React from "react";
import ReactApexChart from 'react-apexcharts';
import Box from "../Box";
import { useTranslation } from "react-i18next";

const LineChart = ({ dataSet, colors, categories, xLabel = '', yLabel = '', legends = [] }) => {
  const {t} = useTranslation();
  const formattedSeries = dataSet
    .filter(series => series && Array.isArray(series.data))  // checking array type
    .map(series => ({
      ...series,
      data: series.data.map(value => parseFloat(value?.toFixed(2) || 0)) // if the array has empty values make it 0
    }));
  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    colors: colors,
    dataLabels: {
      enabled: true,
    },
    grid: {
      show: true,      // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: true  //or just here to disable only x axis grids
        }
      },
      yaxis: {
        lines: {
          show: true  //or just here to disable only y axis
        }
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: t(xLabel),  // Label for X-axis
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#666'
        }
      }
    },
    yaxis: {
      title: {
        text: t(yLabel),  // Label for Y-axis
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#666'
        }
      },
      labels:{
        formatter: (value) => Math.round(value)// y-axis value fixed to 2
      }
    },
    legend: {
      show: true,  // Enable the legend
      position:'top',
      labels: {
        colors: '#333',  // Legend label color
        useSeriesColors: false  // Use custom color for labels (false to use the same as data series)
      },
      markers: {
        fillColors: colors  // Colors for the legend markers
      },
      formatter: function(seriesName, opts) {
        // Use the legendLabels prop to provide custom labels
        return t(legends[opts.seriesIndex]);
      }
    }
  };

  console.log("series", formattedSeries);
  console.log("options", options);

  return (
    <Box>
      <ReactApexChart
        options={options}
        series={formattedSeries}
        type="line"
        height={380}
         width="98%"
        
      />
    </Box>
  );
};

export default LineChart;
