import React from "react";
import ReactApexChart from 'react-apexcharts';
import Box from "../Box";

const BarChart = ({inputSeries, name, data, categories}) => {
    const series = [{
        name: name,
        data: data
    }];

    const options = {
        chart: {
            toolbar: {
                show: false
            },
            stacked: true
        },
        grid: {
            show: false,      // you can either change hear to disable all grids
            xaxis: {
                lines: {
                    show: false  //or just here to disable only x axis grids
                }
            },
            yaxis: {
                lines: {
                    show: false  //or just here to disable only y axis
                }
            },
        },
        colors: ["#80CBC4"],
        plotOptions: {
            bar: {
                borderRadius: 10,
                //dataLabels: {
                //  position: 'top', // top, center, bottom
                //},
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toFixed(2);
            },
            offsetY: 20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },

        xaxis: {
            categories: categories,
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                formatter: function (val) {
                    return val;
                }
            }
        },
    };

    return (
        <Box>
            <ReactApexChart options={options} series={inputSeries ? inputSeries : series} type="bar" height={300}
                            width="85%"
            />
        </Box>
    );
}

export default BarChart;
